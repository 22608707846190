<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      <span>Editar dados</span>

      <v-spacer />

      <v-tooltip
        left
        color="warning"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="expand = !expand"
          >
            <v-icon
              color="warning"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </template>

        <span>Editar</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="data.occupation.name"
        :append-icon="icons.mdiPencil"
        label="Nome da Função"
        outlined
        dense
      />

      <v-text-field
        v-model="data.sector.name"
        label="Setor Atual"
        class="mb-2"
        outlined
        readonly
        dense
      />

      <v-divider class="mb-8" />

      <v-expand-transition>
        <v-card
          v-show="expand"
        >
          <v-autocomplete
            v-model="selectedSectorId"
            :items="sectors"
            item-text="name"
            item-value="id"
            label="Alterar setor"
            clearable
            outlined
            dense
          />
        </v-card>
      </v-expand-transition>

      <div class="d-flex justify-end mt-4">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoading"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },

    sectors: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedSectorId: '',

      expand: false,
      isLoading: false,

      icons: {
        mdiPencil,
      },
    }
  },

  methods: {
    async sendModalData() {
      const { data, inputSector } = this

      this.isLoading = true

      const body = {
        name: data.occupation.name.toUpperCase(),
        sectors_id: inputSector || data.sector.id,
      }

      await axiosIns.put(`/api/v1/records/occupation/update/${data.id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Informações atualizadas',
            text: 'alterado com sucesso',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao atualizar dados',
            text: error,
          })
        }).finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    clearDataFromInputs() {
      this.selectedSectorId = ''
    },
  },
}
</script>
